import React, { Suspense, useEffect, useReducer } from "react";
import { HashRouter, Switch } from "react-router-dom";
// import { CSpinner } from "@coreui/react-pro";
import { AuthContext } from './auth/authContext';
import { authReducer } from "./auth/authReducer";
import { ProtectedRoute } from "./routers/ProtectedRoute";
import "./scss/style.scss";
import { PublicRoute } from "./routers/PublicRoute";
import Spinner from "./app/common/SpinnerTai";

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

const Login = React.lazy(() => import("./views/login/Login"));
const Verify = React.lazy(() => import("./views/login/Verify"));
const RestorePassword = React.lazy(() => import("./views/login/RestorePassword"));
const ResetPassword = React.lazy(() => import("./views/login/ResetPassword"));
const Home = React.lazy(() => import("./views/home/Home"));
const Match = React.lazy(() => import("./views/match/Match"));
const EditMatch = React.lazy(() => import("./views/match/EditMatch"));
const StepOne = React.lazy(() => import("./views/match/StepOne"));
const StepTwo = React.lazy(() => import("./views/match/StepTwo"));
const StepThree = React.lazy(() => import("./views/match/StepThree"));
const Results = React.lazy(() => import("./views/match/Results"));
const Ranking = React.lazy(() => import("./views/ranking/Ranking"));
const Profile = React.lazy(() => import("./views/profile/Profile"));
const EditProfile = React.lazy(() => import("./views/profile/EditProfile"));
const Articles = React.lazy(() => import("./views/articles/Articles"));
const Test = React.lazy(() => import("./views/test/Test"));
const Article = React.lazy(() => import("./views/articles/Article"));
const AboutUs = React.lazy(() => import("./views/aboutUs/AboutUs"));
const PrivacyPolicy = React.lazy(() => import("./views/aboutUs/PrivacyPolicy"));
const TermsConditions = React.lazy(() => import("./views/aboutUs/TermsConditions"));
const Legal = React.lazy(() => import("./views/aboutUs/Legal"));
const Payment = React.lazy(() => import("./views/payment/PaymentStep1"));
const PaymentStatus = React.lazy(() => import("./views/payment/PaymentStatus"));
const Error = React.lazy(() => import("./views/payment/Error"));
const ResultsViewTable = React.lazy(() => import("./views/match/ResultsViewTable"));
const init = () => {
  return JSON.parse(localStorage.getItem('user')) || { logged: false };
}
export default function App() {
  const [user, dispatch] = useReducer(authReducer, {}, init)
  useEffect(()=>{
    if(!user) return;
    localStorage.setItem('user', JSON.stringify({email:user.email,logged:user.logged, token:user.token, user:user.user, profile:user.profile}))
  }, [user])
  
  return (
    <AuthContext.Provider value={{user,dispatch}}>
      <HashRouter>
        <Suspense fallback={<Spinner/>}>
          <Switch>
            <PublicRoute
              exact
              path="/login"
              name="Login Page"
              component={(props) => <Login {...props} />}
            />
            <PublicRoute
              exact
              path="/verify/:id"
              name="Verify Page"
              component={(props) => <Verify {...props} />}
            />
            <PublicRoute
              exact
              path="/recuperar-password"
              name="Login Page"
              component={(props) => <RestorePassword {...props} />}
            />
                        <PublicRoute
              exact
              path="/reset-password"
              name="Login Page"
              component={(props) => <ResetPassword {...props} />}
            />
            <PublicRoute
              exact
              path="/home"
              name="Home Page"
              component={(props) => <Home {...props} />}
            />
            <PublicRoute
              exact
              path="/match"
              name="Match Page"
              component={(props) => <Match {...props} />}
            />
            <ProtectedRoute
             path="/match/:profile"
             name="Editar match"
             component={(props) => <EditMatch {...props} />}
            />
            <PublicRoute
              exact
              path="/matchOld"
              name="Match Page"
              component={(props) => <StepOne {...props} />}
            />
            <PublicRoute
              exact
              path="/match2"
              name="Match Page"
              component={(props) => <StepTwo {...props} />}
            />
            <PublicRoute
              exact
              path="/match3"
              name="Match Page"
              component={(props) => <StepThree {...props} />}
            />
            <PublicRoute
              exact
              path="/resultados/:profile"
              name="Match Page"
              component={(props) => <Results {...props} />}
            />
            <PublicRoute
              exact
              path="/resultados-tabla"
              name="Profile Page"
              component={(props) => <ResultsViewTable {...props} />}
            />
            <ProtectedRoute
              exact
              path="/ranking"
              name="Ranking Page"
              component={(props) => <Ranking {...props} />}
            />
            <PublicRoute
              exact
              path="/perfil"
              name="Profile Page"
              component={(props) => <Profile {...props} />}
            />
            <PublicRoute
              exact
              path="/editar-perfil"
              name="Profile Page"
              component={(props) => <EditProfile {...props} />}
            />
            <PublicRoute
              exact
              path="/articulos"
              name="Profile Page"
              component={(props) => <Articles {...props} />}
            />
            <PublicRoute
              exact
              path="/articulo/:id"
              name="Profile Page"
              component={(props) => <Article {...props} />}
            />
            <PublicRoute
              exact
              path="/test"
              name="Profile Page"
              component={(props) => <Test {...props} />}
            />
            <PublicRoute
              exact
              path="/nosotros"
              name="Profile Page"
              component={(props) => <AboutUs {...props} />}
            />
            <PublicRoute
              exact
              path="/politica-privacidad"
              name="Profile Page"
              component={(props) => <PrivacyPolicy {...props} />}
            />
            <PublicRoute
              exact
              path="/terminos-condiciones"
              name="Profile Page"
              component={(props) => <TermsConditions {...props} />}
            />
            <PublicRoute
              exact
              path="/legal"
              name="Profile Page"
              component={(props) => <Legal {...props} />}
            />
            <ProtectedRoute
             path="/pago"
             name="Pago"
             component={(props) => <Payment {...props} />}
            />
            <ProtectedRoute
             path="/resumen-compra"
             name="Pago"
             component={(props) => <PaymentStatus {...props} />}
            />
            <ProtectedRoute
             path="/error"
             name="Error en Pago"
             component={(props) => <Error {...props} />}
            />
            <ProtectedRoute
             path="/"
             name="Home"
             component={(props) => <DefaultLayout {...props} />}
            />
          
            {/* <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            /> */}
          </Switch>
        </Suspense>
      </HashRouter>
    </AuthContext.Provider>
  );
  
}

// export default App;
