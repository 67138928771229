
import React from "react";

const Spinner = (props) => {

  return (
    <>
    <div className="d-flex align-items-center justify-content-center">
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    </>
  );
};

export default Spinner;
